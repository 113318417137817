import { useContext } from "react";
import { Chip, List, ListItem, Stack } from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Link } from "react-router-dom";

import { SummaryListButton, SummaryStack } from "./styles";
import AuthenticationContext from "../../../context/AuthenticationContext";
import { useCaseList } from "../../../hooks/Cases/useCaseList";
import {CaseListFilterContext} from "../../../context/CaseListFilters";
import JourneyContext from "../../../context/JourneyContext";

const SummaryWidget = () => {
  const { defaultBrandId } = useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);
  const { summaryCases } = useCaseList(currentJourney?.brandId || defaultBrandId);
  const { setFilterStatuses, setFilterDate, setFilterDateEnd, setFilterText } = useContext(CaseListFilterContext);
  
  const handleCaseLink = (filters: string[]) => () => {
    setFilterStatuses(filters);
    setFilterText("");
    setFilterDate("");
    setFilterDateEnd("");
  }

  const summaryCount = (filters: string[]) => {
   
    let filterArray: string[] = []
    filters.forEach(filter => {
      filterArray = filterArray.concat(filter)
    })

    let statuses: string[] = []
    filterArray.forEach(filter => {
      statuses = statuses.concat(currentJourney?.uiConfig?.filter[filter]?.statuses ?
          currentJourney?.uiConfig?.filter[filter]?.statuses as string[] : [])
    })
   
    return summaryCases && summaryCases.filter(item => statuses.includes(item.status)).length
  };

  return (
    <List>
      {(currentJourney?.uiConfig ? currentJourney.uiConfig.summary : []).map(({ label, color, filter }, index) => (
        <ListItem disablePadding dense key={index}>
          <Link
            to={`/cases`}
            onClick={handleCaseLink(filter as string[])}
            style={{ color: "#000", width: "100%", textDecoration: "none" }}
          >
            <SummaryListButton>
              <SummaryStack direction="row" useFlexGap flexWrap="wrap">
                <p>{label}</p>
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Chip
                    label={summaryCount(filter)}
                    variant="outlined"
                    className={label}
                    sx={{
                      background: color,
                    }}
                  />
                  <ArrowForwardIosOutlinedIcon fontSize="small" />
                </Stack>
              </SummaryStack>
            </SummaryListButton>
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

export default SummaryWidget;
